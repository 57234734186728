import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'merytus-iframe';
  showIframe = false;
  url: SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://app.merytu.com/#/login");

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {

   }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        if (params.r && params.r == "BUSINESS") {
          this.url =
          this.sanitizer.bypassSecurityTrustResourceUrl("https://app.merytu.com/#/login-business;action=BUSINESS_START");
      
        }
        this.showIframe = true;
    
      }
    );


  }

}
